.login {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(238, 174, 202);
  background: -moz-linear-gradient(
    90deg,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eeaeca",endColorstr="#94bbe9",GradientType=1);
  &__content {
    background-color: aliceblue;
    padding: 50px 50px;
    border-radius: 20px;
    width: 500px;
    -webkit-box-shadow: 0px -2px 30px 11px rgba(255, 255, 255, 0.4);
    -moz-box-shadow: 0px -2px 30px 11px rgba(255, 255, 255, 0.4);
    box-shadow: 0px -2px 30px 11px rgba(255, 255, 255, 0.4);

    > h1 {
      font-size: 35px;
      text-align: center;
      margin-bottom: 50px;
    }
  }
}
