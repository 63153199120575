.side-menu-admin {
  &.visible {
    .ui.menu {
      width: 250px !important;
      overflow: hidden;
      padding-top: 4em;
      z-index: 99 !important;

      i.icon {
        float: initial !important;
        margin: 0 !important;
        margin-right: 10px !important;
      }
    }

    .content {
      position: absolute;
      right: 0;
      padding: 20px;
      width: calc(100% - 250px);
    }

    .menu-toggle {
      position: absolute;
      top: 40px;
      left: 5.7em; /* Cambia la posición del botón al darle ocultar */
      z-index: 100;
      transition: font-size 0.3s; /* Agrega la transición para el tamaño del botón */

      &.hidden {
        background: transparent; /* Cambia el fondo a transparente */
        padding: 0; /* Elimina el relleno del botón */
        margin: 0; /* Elimina el margen del botón */
        font-size: 3em; /* Cambia el tamaño del botón oculto */
      }

      &.visible {
        font-size: 2em; /* Cambia el tamaño del botón visible */
      }
    }
  }

  &.hidden {
    .ui.menu {
      display: none;
    }

    .content {
      margin-left: 3rem; /* Cambié margin-right por margin-left */
      width: calc(
        100% - 4rem
      ); /* Agregué esta línea para ajustar el ancho del contenido */
    }

    .menu-toggle {
      position: absolute;
      top: 45px;
      left: 0px; /* Vuelve a la posición original del botón al desocultar */
      z-index: 100;
      transition: font-size 0.3s; /* Agrega la transición para el tamaño del botón */

      &.hidden {
        background: transparent; /* Cambia el fondo a transparente */
        padding: 0; /* Elimina el relleno del botón */
        margin: 0; /* Elimina el margen del botón */
        font-size: 1em; /* Cambia el tamaño del botón oculto */
      }

      &.visible {
        font-size: 1em; /* Cambia el tamaño del botón visible */
      }
    }
  }
}
