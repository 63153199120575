.searchbar-container {
  display: flex;
  align-items: center;
}

.searchbar-wrapper {
  margin-right: 10px;
}

.counter-wrapper {
  font-size: 14px;
  margin-left: auto;
}
